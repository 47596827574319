// Packages
import React, { useContext, useEffect, useRef } from 'react'
// UI
import Loading from '../../ui/LoadingBlocker'
// Contexts
import { FormDataContext } from '../../contexts/FormData'

function ServiceabilityCheckBlockerBasic({ onNext }: any) {
    // Contexts
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    // Refs
    const useEffectBlocker = useRef(false)

    useEffect(() => {
        if (useEffectBlocker.current) return

        if (formData?.topServiceableCompany && formData?.existingProvider) {
            formDataDispatch({
                type: 'SET_FIELD',
                payload: {
                    name: 'existingCustomer',
                    value: formData?.topServiceableCompany.toLowerCase() === formData?.existingProvider.toLowerCase(),
                },
            })
        }

        if (formData?.topServiceableCompany) {
            onNext()
            useEffectBlocker.current = true
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData?.topServiceableCompany])

    return <Loading text="This may take up to 30 secs..." useLoadingBar />
}

export default ServiceabilityCheckBlockerBasic
