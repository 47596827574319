/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
// Packages
import React, { useContext, useState, useEffect } from 'react'

// UI
import Card from './Card'
import TitleText2 from './TitleText2'
import BodyText2 from './BodyText2'
import HeadingText5 from './HeadingText5'
// Contexts
import { StylesContext } from '../contexts/Styles'
import { ConfigContext } from '../contexts/Config'
import { QueryParamsContext } from '../contexts/QueryParams'
import { FormDataContext } from '../contexts/FormData'
// Helpers
import getConfigData from '../helpers/config/getConfigData'
import ConditionalRender from '../components/ConditionalRender'

function InfoCard({
    className = '',
    children,
    type,
    plan,
    planPrice,
    fullName,
    phoneNumber,
    address,
    planTypeCopy,
    titleCopy,
    nameCopy,
    phoneCopy,
    addressCopy,
    topServiceableCompany,
    deviceCopy,
    devicePriceCopy,
    monthlyPriceCopy,
    monthlyPriceValue,
    taxesCopy,
    oneTimeFeeCopy,
    oneTimeFeeValue,
    firstMonthBillCopy,
    firstMonthBillValue,
    billsDisclosure,
}: {
    [key: string]: any
}) {
    // Contexts
    const [styles]: any = useContext(StylesContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    const [config]: any = useContext(ConfigContext)
    const [formData]: any = useContext(FormDataContext)
    // States
    const [logo, setLogo]: any = useState({})

    useEffect(() => {
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: `${topServiceableCompany}Logo.png`,
        }).then((data: { [key: string]: any }) => {
            setLogo(data)
        })
    }, [])

    if (Object.keys(styles).length === 0) {
        return null
    }

    switch (type) {
        case 'branded': {
            return (
                <Card
                    className={styles.twMerge(
                        styles?.ui?.InfoCard?.branded?.Card?.mobile,
                        styles?.ui?.InfoCard?.branded?.Card?.tablet,
                        styles?.ui?.InfoCard?.branded?.Card?.desktop,
                        className
                    )}
                >
                    <div
                        className={styles.twMerge(
                            styles?.ui?.InfoCard?.branded?.imgContainer?.mobile,
                            styles?.ui?.InfoCard?.branded?.imgContainer?.tablet,
                            styles?.ui?.InfoCard?.branded?.imgContainer?.desktop
                        )}
                    >
                        <img
                            className={styles.twMerge(
                                styles?.ui?.InfoCard?.branded?.img?.mobile,
                                styles?.ui?.InfoCard?.branded?.img?.tablet,
                                styles?.ui?.InfoCard?.branded?.img?.desktop
                            )}
                            src={logo.url}
                            alt={logo.alt}
                        />
                        <TitleText2>
                            {plan} {planTypeCopy}
                        </TitleText2>
                    </div>
                    <div
                        className={styles.twMerge(
                            styles?.ui?.InfoCard?.branded?.bodyContainer?.mobile,
                            styles?.ui?.InfoCard?.branded?.bodyContainer?.tablet,
                            styles?.ui?.InfoCard?.branded?.bodyContainer?.desktop
                        )}
                    >
                        <HeadingText5
                            className={styles.twMerge(
                                styles?.ui?.InfoCard?.branded?.title?.mobile,
                                styles?.ui?.InfoCard?.branded?.title?.tablet,
                                styles?.ui?.InfoCard?.branded?.title?.desktop
                            )}
                        >
                            {titleCopy}
                        </HeadingText5>
                        <div
                            className={styles.twMerge(
                                styles?.ui?.InfoCard?.branded?.elementContainer?.mobile,
                                styles?.ui?.InfoCard?.branded?.elementContainer?.tablet,
                                styles?.ui?.InfoCard?.branded?.elementContainer?.desktop
                            )}
                        >
                            <TitleText2>{nameCopy}</TitleText2>
                            &nbsp;
                            <BodyText2
                                className={styles.twMerge(
                                    styles?.ui?.InfoCard?.branded?.elementValue?.mobile,
                                    styles?.ui?.InfoCard?.branded?.elementValue?.tablet,
                                    styles?.ui?.InfoCard?.branded?.elementValue?.desktop
                                )}
                            >
                                {fullName}
                            </BodyText2>
                        </div>
                        <div
                            className={styles.twMerge(
                                styles?.ui?.InfoCard?.branded?.elementContainer?.mobile,
                                styles?.ui?.InfoCard?.branded?.elementContainer?.tablet,
                                styles?.ui?.InfoCard?.branded?.elementContainer?.desktop
                            )}
                        >
                            <TitleText2>{phoneCopy}</TitleText2>
                            &nbsp;
                            <BodyText2
                                className={styles.twMerge(
                                    styles?.ui?.InfoCard?.branded?.elementValue?.mobile,
                                    styles?.ui?.InfoCard?.branded?.elementValue?.tablet,
                                    styles?.ui?.InfoCard?.branded?.elementValue?.desktop
                                )}
                            >
                                {phoneNumber}
                            </BodyText2>
                        </div>
                        <div
                            className={styles.twMerge(
                                styles?.ui?.InfoCard?.branded?.elementContainer?.mobile,
                                styles?.ui?.InfoCard?.branded?.elementContainer?.tablet,
                                styles?.ui?.InfoCard?.branded?.elementContainer?.desktop
                            )}
                        >
                            <TitleText2>{addressCopy}</TitleText2>
                            &nbsp;
                            <BodyText2
                                className={styles.twMerge(
                                    styles?.ui?.InfoCard?.branded?.elementValue?.mobile,
                                    styles?.ui?.InfoCard?.branded?.elementValue?.tablet,
                                    styles?.ui?.InfoCard?.branded?.elementValue?.desktop
                                )}
                            >
                                {address}
                            </BodyText2>
                        </div>
                    </div>
                </Card>
            )
        }
        case 'spectrum': {
            return (
                <Card
                    className={styles.twMerge(
                        styles?.ui?.InfoCard?.spectrum?.Card?.mobile,
                        styles?.ui?.InfoCard?.spectrum?.Card?.tablet,
                        styles?.ui?.InfoCard?.spectrum?.Card?.desktop,
                        className
                    )}
                >
                    <div
                        className={styles.twMerge(
                            styles?.ui?.InfoCard?.spectrum?.imgContainer?.mobile,
                            styles?.ui?.InfoCard?.spectrum?.imgContainer?.tablet,
                            styles?.ui?.InfoCard?.spectrum?.imgContainer?.desktop
                        )}
                    >
                        <img
                            className={styles.twMerge(
                                styles?.ui?.InfoCard?.spectrum?.img?.mobile,
                                styles?.ui?.InfoCard?.spectrum?.img?.tablet,
                                styles?.ui?.InfoCard?.spectrum?.img?.desktop
                            )}
                            src={logo.url}
                            alt={logo.alt}
                        />
                    </div>
                    <div
                        className={styles.twMerge(
                            styles?.ui?.InfoCard?.spectrum?.bodyContainer?.mobile,
                            styles?.ui?.InfoCard?.spectrum?.bodyContainer?.tablet,
                            styles?.ui?.InfoCard?.spectrum?.bodyContainer?.desktop
                        )}
                    >
                        <div
                            className={styles.twMerge(
                                styles?.ui?.InfoCard?.spectrum?.cardSection?.mobile,
                                styles?.ui?.InfoCard?.spectrum?.cardSection?.tablet,
                                styles?.ui?.InfoCard?.spectrum?.cardSection?.desktop
                            )}
                        >
                            <TitleText2>{fullName}</TitleText2>
                            <BodyText2>{phoneNumber}</BodyText2>
                            <BodyText2>{address}</BodyText2>
                        </div>
                        <div
                            className={styles.twMerge(
                                styles?.ui?.InfoCard?.spectrum?.cardSection?.mobile,
                                styles?.ui?.InfoCard?.spectrum?.cardSection?.tablet,
                                styles?.ui?.InfoCard?.spectrum?.cardSection?.desktop
                            )}
                        >
                            <BodyText2
                                className={styles.twMerge(
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.mobile,
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.tablet,
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.desktop
                                )}
                            >
                                <div>
                                    {plan} {planTypeCopy}
                                </div>
                                {planPrice}/mo.
                            </BodyText2>
                            <BodyText2
                                className={styles.twMerge(
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.mobile,
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.tablet,
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.desktop
                                )}
                            >
                                <div>{deviceCopy}</div>
                                <div>${devicePriceCopy}.00/mo.</div>
                            </BodyText2>
                            <TitleText2
                                className={styles.twMerge(
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.mobile,
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.tablet,
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.desktop
                                )}
                            >
                                <div>{monthlyPriceCopy}</div>
                                <div>${monthlyPriceValue}/mo.</div>
                            </TitleText2>
                            <BodyText2>{taxesCopy}</BodyText2>
                        </div>
                        <div
                            className={styles.twMerge(
                                styles?.ui?.InfoCard?.spectrum?.CardSectionNoBorder?.mobile,
                                styles?.ui?.InfoCard?.spectrum?.CardSectionNoBorder?.tablet,
                                styles?.ui?.InfoCard?.spectrum?.CardSectionNoBorder?.desktop
                            )}
                        >
                            <BodyText2
                                className={styles.twMerge(
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.mobile,
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.tablet,
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.desktop
                                )}
                            >
                                <div>{oneTimeFeeCopy}</div>
                                <div>${oneTimeFeeValue}.00</div>
                            </BodyText2>
                            <TitleText2
                                className={styles.twMerge(
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.mobile,
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.tablet,
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.desktop
                                )}
                            >
                                <div>{firstMonthBillCopy}</div>
                                <div>${firstMonthBillValue}</div>
                            </TitleText2>
                            <BodyText2>{billsDisclosure}</BodyText2>
                        </div>
                    </div>
                </Card>
            )
        }
        case 'bundledOffer': {
            return (
                <Card
                    className={styles.twMerge(
                        styles?.ui?.InfoCard?.spectrum?.Card?.mobile,
                        styles?.ui?.InfoCard?.spectrum?.Card?.tablet,
                        styles?.ui?.InfoCard?.spectrum?.Card?.desktop,
                        className
                    )}
                >
                    <div
                        className={styles.twMerge(
                            styles?.ui?.InfoCard?.spectrum?.imgContainer?.mobile,
                            styles?.ui?.InfoCard?.spectrum?.imgContainer?.tablet,
                            styles?.ui?.InfoCard?.spectrum?.imgContainer?.desktop
                        )}
                    >
                        <img
                            className={styles.twMerge(
                                styles?.ui?.InfoCard?.spectrum?.img?.mobile,
                                styles?.ui?.InfoCard?.spectrum?.img?.tablet,
                                styles?.ui?.InfoCard?.spectrum?.img?.desktop
                            )}
                            src={logo.url}
                            alt={logo.alt}
                        />
                    </div>
                    <div
                        className={styles.twMerge(
                            styles?.ui?.InfoCard?.spectrum?.bodyContainer?.mobile,
                            styles?.ui?.InfoCard?.spectrum?.bodyContainer?.tablet,
                            styles?.ui?.InfoCard?.spectrum?.bodyContainer?.desktop
                        )}
                    >
                        <div
                            className={styles.twMerge(
                                styles?.ui?.InfoCard?.spectrum?.cardSection?.mobile,
                                styles?.ui?.InfoCard?.spectrum?.cardSection?.tablet,
                                styles?.ui?.InfoCard?.spectrum?.cardSection?.desktop
                            )}
                        >
                            <TitleText2>{fullName}</TitleText2>
                            <BodyText2>{phoneNumber}</BodyText2>
                            <BodyText2>{address}</BodyText2>
                        </div>
                        <div
                            className={styles.twMerge(
                                styles?.ui?.InfoCard?.spectrum?.cardSection?.mobile,
                                styles?.ui?.InfoCard?.spectrum?.cardSection?.tablet,
                                styles?.ui?.InfoCard?.spectrum?.cardSection?.desktop
                            )}
                        >
                            {formData?.selectedBundle?.rowData.map((row: any) => {
                                return (
                                    <div key={row.title} className="flex flex-row justify-between mb-[5px]">
                                        <p>
                                            {row.title === 'UserInternetPlan'
                                                ? `${formData?.selectedPlan} Internet`
                                                : row.title}
                                        </p>
                                        <div className="flex flex-row gap-[5px]">
                                            <ConditionalRender condition={row?.strikedPrice}>
                                                <p className="text-red-500 line-through">
                                                    {row?.strikedPrice?.replace('/mo', '')}
                                                </p>
                                            </ConditionalRender>
                                            <p>{row.price}</p>
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="flex flex-row justify-between w-full">
                                <p className="max-w-1/2">Monthly Price</p>
                                <TitleText2>{formData?.selectedBundle?.totalBundlePrice}</TitleText2>
                            </div>
                            <BodyText2 className="mt-[8px] text-sm">
                                {
                                    // eslint-disable-next-line no-unsafe-optional-chaining
                                    formData?.selectedBundle?.badge.charAt(0).toUpperCase() +
                                        // eslint-disable-next-line no-unsafe-optional-chaining
                                        formData?.selectedBundle?.badge.slice(1)
                                }
                                . {taxesCopy}
                            </BodyText2>
                        </div>
                        <div
                            className={styles.twMerge(
                                styles?.ui?.InfoCard?.spectrum?.CardSectionNoBorder?.mobile,
                                styles?.ui?.InfoCard?.spectrum?.CardSectionNoBorder?.tablet,
                                styles?.ui?.InfoCard?.spectrum?.CardSectionNoBorder?.desktop
                            )}
                        >
                            <p
                                className={styles.twMerge(
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.mobile,
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.tablet,
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.desktop
                                )}
                            >
                                <div>{oneTimeFeeCopy}</div>
                                <div>${oneTimeFeeValue}.00</div>
                            </p>
                            <TitleText2
                                className={styles.twMerge(
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.mobile,
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.tablet,
                                    styles?.ui?.InfoCard?.spectrum?.textRow?.desktop
                                )}
                            >
                                <div>{firstMonthBillCopy}</div>
                                <div>${firstMonthBillValue}</div>
                            </TitleText2>
                            <BodyText2 className="text-sm">{billsDisclosure}</BodyText2>
                        </div>
                    </div>
                </Card>
            )
        }
        default: {
            return (
                <Card
                    className={styles.twMerge(
                        styles?.ui?.InfoCard?.generic?.div?.mobile,
                        styles?.ui?.InfoCard?.generic?.div?.tablet,
                        styles?.ui?.InfoCard?.generic?.div?.desktop,
                        className
                    )}
                >
                    {children}
                </Card>
            )
        }
    }
}

export default InfoCard
