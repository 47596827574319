/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useEffect, useState } from 'react'

// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Container from '../../ui/Container'
import Heading from '../../ui/Heading'
import SubHeading from '../../ui/SubHeading'
import Button from '../../ui/Button'
// Contexts
import { DataFunnelContext } from '../../contexts/DataFunnel'
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
import { QueryParamsContext } from '../../contexts/QueryParams'
import { StylesContext } from '../../contexts/Styles'
import { StepsContext } from '../../contexts/Steps'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'
import getConfigData from '../../helpers/config/getConfigData'
import Main from '../../ui/Main'

function WelcomeBasic({ onNext }: any) {
    // Contexts
    const [, dataFunnelDispatch]: any = useContext(DataFunnelContext)
    const [steps]: any = useContext(StepsContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData]: any = useContext(FormDataContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    const [styles]: any = useContext(StylesContext)
    // States
    const [image, setImage]: any = useState({})
    const [logo, setLogo]: any = useState({})

    useEffect(() => {
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: 'FcFreedaFriendHighFiveWithComputerBackground.png',
        }).then((data: { [key: string]: any }) => {
            setImage(data)
        })
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: 'BlueFreeConnectLogo.png',
        }).then((data: { [key: string]: any }) => {
            setLogo(data)
        })
    }, [])

    useEffect(() => {
        if (copy?.steps && steps?.currentStep && steps?.main) {
            // NOTE: Keeping in step components for now since adding custom items might be necessary in the future
            const currentStepName = steps?.main[steps?.currentStep].name
            dataFunnelDispatch({
                type: 'SET_COPY_CONTENT',
                payload: {
                    key: currentStepName,
                    value: copy?.steps?.[currentStepName],
                },
            })
        }
    }, [])

    return (
        <Page>
            <Container
                className={styles.twMerge(
                    styles?.steps?.welcomeBasic?.container?.mobile,
                    styles?.steps?.welcomeBasic?.container?.tablet,
                    styles?.steps?.welcomeBasic?.container?.desktop
                )}
            >
                <img
                    className={styles.twMerge(
                        styles?.steps?.welcomeBasic?.image?.mobile,
                        styles?.steps?.welcomeBasic?.image?.tablet,
                        styles?.steps?.welcomeBasic?.image?.desktop
                    )}
                    src={image.url}
                    alt={image.alt}
                />
                <Main>
                    <Heading
                        name="welcomeBasicHeader"
                        className={styles.twMerge(
                            styles?.steps?.welcomeBasic?.Header?.mobile,
                            styles?.steps?.welcomeBasic?.Header?.tablet,
                            styles?.steps?.welcomeBasic?.Header?.desktop
                        )}
                    >
                        <StyleCopy>
                            {parseCopy(copy?.steps?.welcomeBasic?.title, {
                                config,
                                formData,
                                copy: copy?.steps?.welcomeBasic,
                            })}
                        </StyleCopy>
                    </Heading>
                    <SubHeading
                        name="welcomeBasicSubHeader"
                        className={styles.twMerge(
                            styles?.steps?.welcomeBasic?.SubHeader?.mobile,
                            styles?.steps?.welcomeBasic?.SubHeader?.tablet,
                            styles?.steps?.welcomeBasic?.SubHeader?.desktop
                        )}
                    >
                        <StyleCopy>
                            {parseCopy(copy?.steps?.welcomeBasic?.subHeader, {
                                config,
                                formData,
                                copy: copy?.steps?.welcomeBasic,
                            })}
                        </StyleCopy>
                    </SubHeading>
                    <Button
                        type="fill"
                        className={styles.twMerge(
                            styles?.steps?.welcomeBasic?.Button?.mobile,
                            styles?.steps?.welcomeBasic?.Button?.tablet,
                            styles?.steps?.welcomeBasic?.Button?.desktop
                        )}
                        name="welcomeBasicNext"
                        onClick={() => {
                            onNext()
                        }}
                    >
                        <StyleCopy>
                            {parseCopy(copy?.steps?.welcomeBasic?.button, {
                                config,
                                formData,
                                copy: copy?.steps?.welcomeBasic,
                            })}
                        </StyleCopy>
                    </Button>
                    <img
                        className={styles.twMerge(
                            styles?.steps?.welcomeBasic?.logo?.mobile,
                            styles?.steps?.welcomeBasic?.logo?.tablet,
                            styles?.steps?.welcomeBasic?.logo?.desktop
                        )}
                        src={logo.url}
                        alt={logo.alt}
                    />
                </Main>
            </Container>
        </Page>
    )
}

export default WelcomeBasic
