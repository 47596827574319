/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useEffect, useState } from 'react'

// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Container from '../../ui/Container'
import Heading from '../../ui/Heading'
import SubHeading from '../../ui/SubHeading'
import Button from '../../ui/Button'
// Contexts
import { DataFunnelContext } from '../../contexts/DataFunnel'
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
import { StylesContext } from '../../contexts/Styles'
import { QueryParamsContext } from '../../contexts/QueryParams'
import { StepsContext } from '../../contexts/Steps'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'
import getConfigData from '../../helpers/config/getConfigData'
import ConditionalRender from '../../components/ConditionalRender'

function OrderCompleteBasic() {
    // Contexts
    const [, dataFunnelDispatch]: any = useContext(DataFunnelContext)
    const [steps]: any = useContext(StepsContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData]: any = useContext(FormDataContext)
    const [styles]: any = useContext(StylesContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    // States
    const [image, setImage]: any = useState({})

    useEffect(() => {
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: copy?.steps?.orderCompleteBasic?.[formData?.topServiceableCompany]?.imageUrl,
        }).then((data: { [key: string]: any }) => {
            setImage(data)
        })
    }, [])

    useEffect(() => {
        if (copy?.steps && steps?.currentStep && steps?.main) {
            // NOTE: Keeping in step components for now since adding custom items might be necessary in the future
            const currentStepName = steps?.main[steps?.currentStep].name
            dataFunnelDispatch({
                type: 'SET_COPY_CONTENT',
                payload: {
                    key: currentStepName,
                    value: copy?.steps?.[currentStepName],
                },
            })
        }
    }, [])

    return (
        <Page>
            <Container
                className={styles.twMerge(
                    styles?.steps?.orderCompleteBasic?.Container?.mobile,
                    styles?.steps?.orderCompleteBasic?.Container?.tablet,
                    styles?.steps?.orderCompleteBasic?.Container?.desktop
                )}
            >
                <img
                    className={styles.twMerge(
                        styles?.steps?.orderCompleteBasic?.img?.mobile,
                        styles?.steps?.orderCompleteBasic?.img?.tablet,
                        styles?.steps?.orderCompleteBasic?.img?.desktop
                    )}
                    src={image.url}
                    alt={image.alt}
                />
                <Heading
                    className={styles.twMerge(
                        styles?.steps?.orderCompleteBasic?.Heading?.mobile,
                        styles?.steps?.orderCompleteBasic?.Heading?.tablet,
                        styles?.steps?.orderCompleteBasic?.Heading?.desktop
                    )}
                    name="orderCompleteBasicHeader"
                >
                    <StyleCopy>
                        {parseCopy(copy?.steps?.orderCompleteBasic?.[formData?.topServiceableCompany]?.title, {
                            config,
                            formData,
                            copy: copy?.steps?.orderCompleteBasic?.[formData?.topServiceableCompany],
                        })}
                    </StyleCopy>
                </Heading>
                <SubHeading
                    className={styles.twMerge(
                        styles?.steps?.orderCompleteBasic?.SubHeading?.mobile,
                        styles?.steps?.orderCompleteBasic?.SubHeading?.tablet,
                        styles?.steps?.orderCompleteBasic?.SubHeading?.desktop
                    )}
                    name="orderCompleteBasicSubHeader"
                >
                    <StyleCopy>
                        {parseCopy(copy?.steps?.orderCompleteBasic?.[formData?.topServiceableCompany]?.subHeader, {
                            config,
                            formData,
                            copy: copy?.steps?.orderCompleteBasic?.[formData?.topServiceableCompany],
                        })}
                    </StyleCopy>
                </SubHeading>
                <ConditionalRender
                    condition={copy?.steps?.orderCompleteBasic?.[formData?.topServiceableCompany]?.button}
                >
                    <Button
                        className={styles.twMerge(
                            styles?.steps?.orderCompleteBasic?.Button?.mobile,
                            styles?.steps?.orderCompleteBasic?.Button?.tablet,
                            styles?.steps?.orderCompleteBasic?.Button?.desktop
                        )}
                        type="ghostWhite"
                        name="orderCompleteBasicCallButton"
                        onClick={() => {
                            window.open(
                                `tel:${parseCopy(copy?.steps?.orderCompleteBasic?.[formData?.topServiceableCompany]?.button?.phoneNumber, { config, formData })}`
                            )
                        }}
                    >
                        <StyleCopy>
                            {parseCopy(
                                copy?.steps?.orderCompleteBasic?.[formData?.topServiceableCompany]?.button?.copy,
                                {
                                    config,
                                    formData,
                                }
                            )}
                        </StyleCopy>
                    </Button>
                </ConditionalRender>
            </Container>
        </Page>
    )
}

export default OrderCompleteBasic
