/* eslint-disable react/prop-types */
// Packages
import React, { useEffect, useState, useContext } from 'react'

// Contexts
import { FormDataContext } from '../contexts/FormData'

function LoadingBar() {
    // State
    const [progress, setProgress] = useState(0)

    // Context
    const [formData]: any = useContext(FormDataContext)

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 85) {
                    // Percentage to stop the loading bar
                    clearInterval(interval)
                    return prevProgress
                }
                const progressPercent = prevProgress + 100 / 3000 // 30 seconds, 100% filled (The next progress percentage of the bar)
                return progressPercent
            })
        }, 10)

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        if (formData?.topServiceableCompany) {
            setProgress(100)
        }
    }, [formData?.topServiceableCompany])

    return (
        <div className="w-full h-4 rounded-[30px] border-2 border-white bg-[#0609CD] relative overflow-hidden flex items-center">
            <div className="bg-green-500 h-4 rounded-[30px]" style={{ width: `${progress}%` }} />
        </div>
    )
}

export default LoadingBar
